import React from 'react'
import { useState, useRef } from "react";
import handWave from '../assets/hand-wave.svg';
import mailIllus from '../assets/mail-illus.svg';
import linkedin from '../assets/linkedin.svg';
import mail from '../assets/mail.svg';
import phone from '../assets/phone.svg';
import MyModal from './modal';
import checkIllus from '../assets/check-illus.svg';
import emailjs from '@emailjs/browser';



export const Footer = () => {
    const [openModal, setOpenModal] = useState(false);
    const formRef = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        if (formRef.current) {
            emailjs
                .sendForm('service_djy4cqo', 'template_hto5vkm', formRef.current, {
                    publicKey: 'gGZp6XqEP0Sh4-o7q',
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                        setOpenModal(true);
                        if (formRef.current) {
                            formRef.current.reset();
                        }
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                        if (formRef.current) {
                            formRef.current.reset();
                        }
                    },
                );
        }
    };
    return (
        <div className="footer w-full p-6 bg-slate-100 ">
            <div className='max-w-[800px] mx-auto'>
                <div className="flex text-slate-900 font-bold leading-8 gap-2 mb-4 justify-center">
                    <img src={handWave} alt="" />
                    I would love to chat with you.
                    <br />
                    Don’t hesitate to contact me on...
                </div>
                <div className="links flex flex-col gap-2 items-center mb-8">

                    <a href="https://linkedin.com/in/bachircherrat" className='group transition duration-200 ease-in-out w-fit flex flex-col text-base'>
                        <div className="flex gap-2 justify-center font-medium text-slate-900 w-fit">
                            <img src={linkedin} alt="" />
                            linkedin.com/in/bachircherrat/ &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 ease-in-out h-[1.5px] bg-slate-900" />
                    </a>
                    <a href="mailto:cherratbachir@gmail.com" className='group transition duration-200 ease-in-out w-fit flex flex-col text-base'>
                        <div className="flex gap-2 justify-center font-medium text-slate-900 w-fit">
                            <img src={mail} alt="" />
                            cherratbachir@gmail.com &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 ease-in-out h-[1.5px] bg-slate-900" />
                    </a>
                    <a href="tel:+212654115866" className='group transition duration-200 ease-in-out w-fit flex flex-col text-base'>
                        <div className="flex gap-2 justify-center font-medium text-slate-900 w-fit">
                            <img src={phone} alt="" />
                            (+212) 654 11 58 66 &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 ease-in-out h-[1.5px] bg-slate-900" />
                    </a>
                </div>
                <div className="flex text-slate-900 font-bold leading-8 gap-2 mb-4 justify-center">
                    ... or leave me a message,<br />and I’ll get back to you

                    <img src={mailIllus} alt="" />
                </div>
                <form className="form flex flex-col gap-1 items-center max-w-[480px] mx-auto" ref={formRef} onSubmit={sendEmail}>
                    <label for="mail" className='w-full'>Your email</label>
                    <input id="mail" type='email' className='h-10 w-full rounded-full border border-slate-300 mb-2 p-4' required name="user_email" />
                    <span className='w-full text-sm -mt-2'></span>
                    <label for="message" className='w-full'>Message</label>
                    <textarea id="message" rows="4" className='w-full rounded-2xl border border-slate-300 mb-4 resize-none px-3 py-2' minLength='10' required name="message" />
                    <span className='w-full text-sm -mt-4 mb-2'></span>
                    <button type='submit' className='bg-slate-900 cursor-pointer w-full text-center text-white leading-5 rounded-full px-5 py-[10px] hover:outline hover:outline-offset-2 hover:outline-1 hover:outline-slate-900' >
                        Send message
                    </button>

                </form>
            </div>
            <MyModal openModal={openModal} setOpenModal={setOpenModal}>
                <img src={checkIllus} className="w-20" alt="" />
                <p className="text-lg font-medium leading-relaxed text-slate-900 text-center">
                    Your message was sent. <br />
                    I'll get back to you ASAP.
                </p>
                <div
                    onClick={() => setOpenModal(false)}
                    className="bg-slate-900 w-full text-center text-white leading-5 rounded-full px-5 py-[10px] hover:outline hover:outline-offset-2 hover:outline-1 hover:outline-slate-900 cursor-pointer"
                >
                    Got it
                </div>
            </MyModal>
        </div>
    )
}