import { Header } from './components/header';
import profile from './assets/bachir.jpg';
import hotelBooking from './assets/hotel-booking.jpg';
import hotelBookingB2b from './assets/hotel-booking-b2b-2.jpg';
import hotelMapping from './assets/hotel-mapping.jpg';
import hrPlatform from './assets/hr-platform-0.jpg';
import cupid from './assets/cupid-1.jpg';
import nuitee from './assets/nuitee-0.jpg';
import arrow from './assets/arrow.svg';
import './App.css';
import AutoPlay from './components/slider';
import { Footer } from './components/footer';
import React, { useState } from 'react';
import MyModal from './components/modal';
import lockIllus from './assets/lock.svg'


function App() {
  const [activeTab, setActiveTab] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  // Function to handle tab clicks
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <>
      <div className="max-w-[800px] mx-auto">
        <Header />
        <div className="hero flex flex-col px-4 items-center mt-24 mb-4">
          <img src={profile} alt="" className='w-20 mb-2' />
          <div className="badge flex mb-2 px-2 py-[3px] bg-[#F5F7F9] rounded-full items-center gap-2">
            <div className="ellipse w-4 h-4 bg-green-400 rounded-full border-green-200 border-4"></div>
            Open to work
          </div>
          <div className="headline font-bold text-xl text-slate-900 mb-1 text-center">
            Designing products that<br />actually solve problems
          </div>
          <div className="sub-headline text-[15px] leading-6 text-slate-600 text-center">
            +5 years exp designing user interfaces that<br />are still better than the ones generated by AI.<br />Worked as full time UX designer with:</div>
        </div>
        <AutoPlay />
        {/* <div className="tabs relative flex rounded-full border-slate-200 border bg-slate-50 p-[6px] mt-10 max-w-[480px] mx-4 sm:mx-auto">
          <div
            className={`rectangle absolute h-5 bg-white shadow rounded-full z-10 transition-all duration-500 ease-[cubic-bezier(0.175, 0.885, 0.32, 1.275)] ${activeTab === 1 ? 'left-[6px]' : 'left-1/2'
              }`}
            style={{
              width: 'calc(50% - 6px)',
              height: 'calc(100% - 12px)',
            }}
          ></div>
          <div
            className={`tab z-20 text-base py-[2px] flex-1 text-center rounded-full cursor-pointer transition-all hover:text-slate-900 hover:font-medium ${activeTab === 1 ? ' text-slate-900 font-medium' : 'text-slate-600'
              }`}
            onClick={() => handleTabClick(1)}
          >
            Products
          </div>
          <div
            className={`tab z-20 text-base py-[2px] flex-1 text-center rounded-full cursor-pointer transition-all hover:text-slate-900 hover:font-medium ${activeTab === 2 ? 'text-slate-900 font-medium' : 'text-slate-600'
              }`}
            onClick={() => handleTabClick(2)}
          >
            Landing pages
          </div>
        </div> */}
        <div className='flex w-full overflow-hidden'>
          <div className={`usecases px-4 grid grid-cols-1 md:grid-cols-2 gap-10 my-10 w-[100%] min-w-full ease-in-out transition-all duration-500 ${activeTab === 1 ? 'translate-x-0' : '-translate-x-full'
            }`}>
            <a href="/hotel-booking" className="usecase group" >
              <img src={hotelBooking} alt="" className='group-hover:-translate-y-2  ease-in-out transition-all duration-200' />
              <div className="title flex justify-start gap-2 mt-3 text-lg font-bold text-slate-900">
                Hotel Booking App
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
            <a href="/recruitment-solution" className="usecase group">
              <img src={hrPlatform} alt="" className='group-hover:-translate-y-2  ease-in-out transition-all duration-200' />
              <div className="title flex gap-2 mt-3 text-lg font-bold text-slate-900">
                HR Recruitment Platform
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
            <a onClick={() => setOpenModal(true)} className="usecase group cursor-pointer">
              <div className='relative group-hover:-translate-y-2  ease-in-out transition-all duration-200'>
                <img src={hotelMapping} alt="" />
                <div className="badge flex mb-2 px-2 py-[3px] bg-slate-200 rounded-full items-center gap-2 absolute top-4 right-4 shadow-sm border-slate-200 border">
                  🔒 Private
                </div>
              </div>
              <div className="title flex gap-2 mt-3 text-lg font-bold text-slate-900">
                Hotel Mapping Solution
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
            <a onClick={() => setOpenModal(true)} className="usecase group cursor-pointer">
              <div className='relative group-hover:-translate-y-2  ease-in-out transition-all duration-200'>
                <img src={hotelBookingB2b} alt="" />
                <div className="badge flex mb-2 px-2 py-[3px] bg-slate-200 rounded-full items-center gap-2 absolute top-4 right-4 shadow-sm border-slate-200 border">
                  🔒 Private
                </div>
              </div>
              <div className="title flex gap-2 mt-3 text-lg font-bold text-slate-900">
                Bookings Management for B2B
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
            <a onClick={() => setOpenModal(true)} href='https://cupid.travel/' target='_blank' className="usecase group cursor-pointer" >
            <div className='relative group-hover:-translate-y-2  ease-in-out transition-all duration-200'>
                <img src={cupid} alt="" className='rounded-xl overflow-hidden' />
                {/* <div className="badge flex mb-2 px-2 py-[3px] bg-slate-200 rounded-full items-center gap-2 absolute top-4 right-4 shadow-sm border-slate-200 border">
                  🔒 Private
                </div> */}
              </div>
              <div className="title flex justify-start gap-2 mt-3 text-lg font-bold text-slate-900">
                Hotel Mapping Website
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
            <a onClick={() => setOpenModal(true)} href='https://nuitee.com/' target='_blank' className="usecase group cursor-pointer">
            <div className='relative group-hover:-translate-y-2  ease-in-out transition-all duration-200'>
                <img src={nuitee} alt="" />
                {/* <div className="badge flex mb-2 px-2 py-[3px] bg-slate-200 rounded-full items-center gap-2 absolute top-4 right-4 shadow-sm border-slate-200 border">
                  🔒 Private
                </div> */}
              </div>
              <div className="title flex gap-2 mt-3 text-lg font-bold text-slate-900">
                Hotel API Website
                <img src={arrow} alt="" className='group-hover:ml-1 ease-in-out transition-all duration-200' />
              </div>
            </a>
          </div>
          <div className={`usecases px-4 grid grid-cols-1 md:grid-cols-2 gap-10 my-10 w-[100%] min-w-full ease-in-out transition-all duration-500 ${activeTab === 2 ? '-translate-x-full' : 'translate-x-0'
            }`}>

          </div>
        </div>
      </div>

      <MyModal openModal={openModal} setOpenModal={setOpenModal}>
        <img src={lockIllus} className="w-20" alt="" />
        <p className="text-lg font-medium leading-relaxed text-slate-900 text-center">
          I'm sorry but this project is not public.<br />
          Send me a message and I'll be happy to walk you through it.
        </p>
        <div
          onClick={() => setOpenModal(false)}
          className="bg-slate-900 w-full text-center text-white leading-5 rounded-full px-5 py-[10px] hover:outline hover:outline-offset-2 hover:outline-1 hover:outline-slate-900 cursor-pointer"
        >
          Got it
        </div>
      </MyModal>
      <Footer />
    </>

  );
}

export default App;
