import { Flowbite } from 'flowbite-react';
import { Modal } from "flowbite-react";

const customTheme = {
    modal: {
        "content": {
            "base": "relative h-fit w-5/6 my-auto rounded-3xl",
            "inner": "rounded-3xl bg-white"
        },
    },
    mode: 'light',
};

export default function MyModal({ openModal, setOpenModal, children }) {
    return (
      <Flowbite theme={{ theme: customTheme }}>
        <Modal
          dismissible
          show={openModal}
          position="bottom-center"
          size="sm"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header className="border-none p-6 pb-0" />
          <Modal.Body className="flex flex-col gap-4 p-6 pt-0 items-center">
            {children}
          </Modal.Body>
        </Modal>
      </Flowbite>
    );
  }