import React from "react";
import Slider from "react-slick";
import nuitee from "../assets/nuitee.svg";
import logigroup from "../assets/logigroup.svg";
import pentalog from "../assets/pentalog.svg";
import globant from "../assets/globant.svg";
import wiggli from "../assets/wiggli.svg";


function AutoPlay() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <div className="slider-container w-full">
      <Slider {...settings}>
        <img src={nuitee} alt="" className="h-6 w-auto object-fill" />
        <img src={logigroup} alt="" className="h-4 w-auto object-fill" />
        <img src={globant} alt="" className="h-6 w-auto object-fill" />
        <img src={pentalog} alt="" className="h-6 w-auto object-fill" />
        <img src={wiggli} alt="" className="h-6 w-auto object-fill" />
      
      </Slider>
    </div>
  );
}

export default AutoPlay;