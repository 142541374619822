import React from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import arrow from '../assets/arrow.svg'
import play from '../assets/play.svg'
import hotelBooking1 from '../assets/hotel-booking-1.jpg'
import hotelBooking2 from '../assets/hotel-booking-2.jpg'
import hotelBooking3 from '../assets/hotel-booking-03.jpg'
import hotelBooking4 from '../assets/hotel-booking-04.jpg'
import hotelBooking5 from '../assets/hotel-booking-05.jpg'
import hotelBooking6 from '../assets/hotel-booking-06.jpg'
import hotelBooking7 from '../assets/hotel-booking-07.jpg'
import hotelBooking8 from '../assets/hotel-booking-08.jpg'
import hotelBooking10 from '../assets/hotel-booking-10.jpg'
import hotelBooking11 from '../assets/hotel-booking-11.jpg'
import hotelBooking12 from '../assets/hotel-booking-12.jpg'

const HotelBooking = () => {
    return (
        <>

            <div className='max-w-[800px] flex flex-col justify-center mx-auto' >
                <Header />
                <div className='main p-4 mt-16 mx-auto'>
                <a href="/" className='group transition duration-300 w-fit flex flex-col mb-6 text-lg'>
                        <div className="flex gap-2 justify-center font-bold text-slate-900 w-fit">
                        <img src={arrow} className='rotate-180' alt="" />
                        Back to home &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-900"/>
                    </a>
                    <img src={hotelBooking1} alt="" />
                    <div className="flex justify-between mt-3">
                        <div className="flex flex-col gap-1 text-sm">
                            <p className='text-slate-600 leading-4'>Role</p>
                            <p className='text-slate-900 leading-4'>UX/UI Designer</p>
                        </div>
                        <div className="flex flex-col gap-1 text-right text-sm">
                            <p className='text-slate-600 leading-4'>Timeline</p>
                            <p className='text-slate-900 leading-4'>Dec 2023 - Mai 2024</p>
                        </div>
                    </div>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Overview</p>
                    <p className='text-slate-600'>
                        This white label product allows businesses to quickly launch a fully customizable travel website without any coding. Businesses can easily integrate their hotel inventories while maintaining their brand identity.
                    </p>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Goal</p>
                    <p className='text-slate-600'>
                    The goal was to redesign the existing platform to an app that is responsive, user-friendly and intuitive to simplify the end-user journey while taking into consideration all the business requirements.
                    </p>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Information architecture</p>
                    <p className='text-slate-600 mb-3'>
                    After conducting multiple interviews with stakeholders, I created the app's information architecture to ensure it met both the app requirements and user needs, as follow:
                    </p>
                    <img src={hotelBooking2} alt="" />
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-3'>Low-fidelity prototype</p>
                    <img src={hotelBooking3} className='mb-3' alt="" />
                    <img src={hotelBooking4} alt="" />
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-3'>Usability testing</p>
                    <img src={hotelBooking6} className='mb-3' alt="" />
                    <a href="https://drive.google.com/file/d/1NjXSz9xofimqW7554ERlGQLP1Mj7wJmH/view?usp=sharing" target='_blank' className='group transition duration-300 w-fit flex flex-col mx-auto mb-5'>
                        <div className="flex justify-center font-bold text-slate-900 w-fit">
                        <img src={play} alt="" />
                        Watch video &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-900"/>
                    </a>
                    <img src={hotelBooking5} className='mb-3' alt="" />
                    <a href="https://drive.google.com/file/d/1AeiFF0idhmG3E_UQ7XZQ3KIE0wI0GfaW/view?usp=sharing" target='_blank' className='group transition duration-300 w-fit flex flex-col mx-auto mb-5'>
                        <div className="flex justify-center font-bold text-slate-900 w-fit">
                        <img src={play} alt="" />
                        Watch video &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-900"/>
                    </a>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-3'>Old design</p>
                    <img src={hotelBooking7} alt="" />
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-6'>7th Iteration of the design</p>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3'>Home page</p>
                    <img src={hotelBooking12} className='mb-6' alt="" />
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3'>Results page</p>
                    <img src={hotelBooking11} className='mb-6' alt="" />
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3'>Checkout page</p>
                    <img src={hotelBooking10} className='mb-6' alt="" />
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3'>Confirmation page</p>
                    <img src={hotelBooking8} className='mb-6' alt="" />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HotelBooking 