import React from 'react'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import arrow from '../assets/arrow.svg'
import play from '../assets/play.svg'
import cardSorting from '../assets/card-sorting.jpg'
import hrPlatform from '../assets/hr-platform.jpg';
import hrIa from '../assets/ia-hr.jpg';
import wireframe1 from '../assets/wireframe-hr-1.jpg';
import wireframe2 from '../assets/wireframe-hr-2.jpg';
import wireframe3 from '../assets/wireframe-hr-3.jpg';
import wireframe4 from '../assets/wireframe-hr-4.jpg';
import wireframe5 from '../assets/wireframe-hr-5.jpg';
import hrLofi1 from '../assets/hr-lofi-1.jpg';
import hrLofi2 from '../assets/hr-lofi-2.jpg';
import hrLofi3 from '../assets/hr-lofi-3.jpg';
import hrLofi4 from '../assets/hr-lofi-4.jpg';
import hrLofi5 from '../assets/hr-lofi-5.jpg';
import hrLofi6 from '../assets/hr-lofi-6.jpg';
import hrAb from '../assets/ab-hr.jpg';
import hrHifi1 from '../assets/hr-hifi-1.jpg';
import hrHifi2 from '../assets/hr-hifi-2.jpg';
import hrHifi3 from '../assets/hr-hifi-3.jpg';
import hrHifi4 from '../assets/hr-hifi-4.jpg';
import hrHifi5 from '../assets/hr-hifi-5.jpg';
import hrHifi6 from '../assets/hr-hifi-6.jpg';
import hrHifi7 from '../assets/hr-hifi-7.jpg';
import hrHifi8 from '../assets/hr-hifi-8.jpg';
import hrHifi9 from '../assets/hr-hifi-9.jpg';
import hrHifi10 from '../assets/hr-hifi-10.jpg';
import hrHifi11 from '../assets/hr-hifi-11.jpg';
import hrHifi12 from '../assets/hr-hifi-12.jpg';




const RecruitmentSolution = () => {
    return (
        <>

            <div className='max-w-[800px] flex flex-col justify-center mx-auto' >
                <Header />
                <div className='main p-4 mt-16 mx-auto'>
                    <a href="/" className='group transition duration-300 w-fit flex flex-col mb-6 text-lg'>
                        <div className="flex gap-2 justify-center font-bold text-slate-900 w-fit">
                            <img src={arrow} className='rotate-180' alt="" />
                            Back to home &nbsp;
                        </div>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-slate-900" />
                    </a>
                    <img src={hrPlatform} alt="" />
                    <div className="flex justify-between mt-3">
                        <div className="flex flex-col gap-1 text-sm">
                            <p className='text-slate-600 leading-4'>Role</p>
                            <p className='text-slate-900 leading-4'>UX/UI Designer</p>
                        </div>
                        <div className="flex flex-col gap-1 text-right text-sm">
                            <p className='text-slate-600 leading-4'>Timeline</p>
                            <p className='text-slate-900 leading-4'>Jan 2023 - Apr 2023</p>
                        </div>
                    </div>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Overview</p>
                    <p className='text-slate-600'>
                        This app is an all-in-one recruitment solution designed to streamline the entire hiring process, from candidate sourcing to integration. It efficiently manages recruitment, builds and maintains a talent pool, and leverages an integrated ATS for a seamless experience.
                    </p>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Goal</p>
                    <p className='text-slate-600'>
                        The goal was to design a mobile app version to simplify the recruitment process for recruiters. The focus was on:
                        <ul className="list-disc pl-4">
                            <li>Identifying the most essential features for recruiters.</li>
                            <li>Providing an intuitive information architecture.</li>
                            <li>Creating low-fidelity wireframes to validate structure and user flow.</li>
                            <li>Building a beautiful UI that aligns with user expectations.</li>
                            <li>Conducting A/B testing to validate design decisions.</li>
                            <li>Testing desing with actual users to identify issues and enhance usability.</li>
                        </ul>
                    </p>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Card sorting</p>
                    <p className='text-slate-600 mb-3'>
                        I conducted an open card-sorting exercise that allowed participants to group content in a way that made the most sense to them, helping me uncover their mental models and optimize the app's structure for better usability and findability.
                    </p>
                    <img src={cardSorting} alt="" />
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Information architecture</p>
                    <p className='text-slate-600 mb-3'>
                        After conducting multiple card-sorting exercises with stakeholders, the final information architecture of the app was developed as follows:
                    </p>
                    <img src={hrIa} alt="" />
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Wireframes</p>
                    <p className='text-slate-600 mb-3'>
                        I created some initial wireframes to get some early feedback from the stakeholders and users.
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                        <img src={wireframe1} className='rounded-xl border border-slate-300' alt="" />
                        <img src={wireframe2} className='rounded-xl border border-slate-300' alt="" />
                        <img src={wireframe3} className='rounded-xl border border-slate-300' alt="" />
                        <img src={wireframe4} className='rounded-xl border border-slate-300' alt="" />
                        <img src={wireframe5} className='rounded-xl border border-slate-300' alt="" />
                    </div>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Low-fidelity prototype</p>
                    <p className='text-slate-600 mb-3'>
                        ...
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                        <img src={hrLofi1} alt="" />
                        <img src={hrLofi2} alt="" />
                        <img src={hrLofi3} alt="" />
                        <img src={hrLofi4} alt="" />
                        <img src={hrLofi5} alt="" />
                        <img src={hrLofi6} alt="" />
                    </div>
                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>A/B testing</p>
                    <p className='text-slate-600 mb-3'>
                        ...
                    </p>
                    <img src={hrAb} className='rounded-xl border border-slate-300 max-h-[700px] mx-auto' alt="" />

                    <p className='text-xl text-slate-900 leading-5 font-bold mt-6 mb-2'>Final design</p>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3'>Onboarding</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                        <img src={hrHifi1} alt="" />
                        <img src={hrHifi2} alt="" />
                        <img src={hrHifi3} alt="" />
                    </div>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3 mt-6'>Reset password</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                        <img src={hrHifi4} alt="" />
                        <img src={hrHifi5} alt="" />
                        <img src={hrHifi6} alt="" />
                    </div>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3 mt-6'>Login and Home page</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                        <img src={hrHifi7} alt="" />
                        <img src={hrHifi8} alt="" />
                        <img src={hrHifi9} alt="" />
                    </div>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3 mt-6'>Candidates page</p>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                        <img src={hrHifi10} alt="" />
                        <img src={hrHifi11} alt="" />
                        <img src={hrHifi12} alt="" />
                    </div>
                    <p className='text-md text-slate-900 leading-5 font-bold mb-3 mt-6 text-center'>Wanna see more? <br/> Feel free to <span>reach out</span> 👇</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RecruitmentSolution 