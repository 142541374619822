import React from 'react'
import logo from '../logo.svg'
import { Link } from 'react-router-dom';

export const Header = () => {
    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };
    return (
        <div className='fixed ease-in-out transitions duration-500 z-20 top-0 w-full max-w-[800px]' id='header'>
            <div id="header-main" className='flex justify-between p-3 ps-5'>
                <Link to="/">
                    <img src={logo} className="App-logo" alt="logo" />
                </Link>
                <div className='bg-slate-900 text-white leading-5 rounded-full px-5 py-[10px] hover:outline hover:outline-offset-2 hover:outline-1 hover:outline-slate-900' onClick={handleScrollToBottom}>
                    Contact me
                </div>

            </div>
        </div>
    )
}

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 0) {
        document.getElementById("header").classList.add("p-3");
        document.getElementById("header-main").classList.add("backdrop-blur-xl", "bg-slate-100/70", "rounded-full", "shadow-md");
    } else {
        document.getElementById("header").classList.remove("p-3");
        document.getElementById("header-main").classList.remove("backdrop-blur-xl", "bg-slate-100/70", "rounded-full", "shadow-md");
    }
}
